import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import '../../dist/css/bolt-css.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <p>{`Os botões são disponibilizados por uma classes principal: `}<strong parentName="p">{`btn`}</strong>{` e outra classe que define o estilo de cores e formato, exemplo: `}<strong parentName="p">{`btn-primary`}</strong>{`
A classe pode ser adicionada em qualquer elemento, mas é recomendado ser adicionada ao elemento button, assim garante as funcionalidades de `}<em parentName="p">{`focus`}</em>{` e `}<em parentName="p">{`disabled`}</em></p>
    <Playground __position={0} __code={'<div className=\"tw-flex tw-gap-3 tw-items-end\">\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn\">clique aqui</button>\n    <p className=\"tw-body3\">base</p>\n  </div>\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn tw-btn-primary\">clique aqui</button>\n    <p className=\"tw-body3\">primary</p>\n  </div>\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn tw-btn-secondary\">clique aqui</button>\n    <p className=\"tw-body3\">secondary</p>\n  </div>\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn tw-btn-flat\">clique aqui</button>\n    <p className=\"tw-body3\">flat</p>\n  </div>\n  <div className=\"tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-1 tw-rounded\">\n    <button className=\"tw-btn tw-btn-flat\">clique aqui</button>\n    <p className=\"tw-body3 tw-text-black\">flat bg claro</p>\n  </div>\n  <div className=\"tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-1 tw-rounded\">\n    <button className=\"tw-btn tw-btn-outline\">clique aqui</button>\n    <p className=\"tw-body3 tw-text-black\">outline bg claro</p>\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div className="tw-flex tw-gap-3 tw-items-end">
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn">clique aqui</button>
      <p className="tw-body3">base</p>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn tw-btn-primary">clique aqui</button>
      <p className="tw-body3">primary</p>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn tw-btn-secondary">clique aqui</button>
      <p className="tw-body3">secondary</p>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn tw-btn-flat">clique aqui</button>
      <p className="tw-body3">flat</p>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-1 tw-rounded">
      <button className="tw-btn tw-btn-flat">clique aqui</button>
      <p className="tw-body3 tw-text-black">flat bg claro</p>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-1 tw-rounded">
      <button className="tw-btn tw-btn-outline">clique aqui</button>
      <p className="tw-body3 tw-text-black">outline bg claro</p>
    </div>
  </div>
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <br />
    <Playground __position={3} __code={'<div className=\"tw-flex tw-gap-3\">\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn tw-btn-primary\">clique aqui</button>\n    <p className=\"tw-body3\">normal</p>\n  </div>\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn tw-btn-primary\" disabled>\n      clique aqui\n    </button>\n    <p className=\"tw-body3\">disabled</p>\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div className="tw-flex tw-gap-3">
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn tw-btn-primary">clique aqui</button>
      <p className="tw-body3">normal</p>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn tw-btn-primary" disabled>
        clique aqui
      </button>
      <p className="tw-body3">disabled</p>
    </div>
  </div>
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "base-rounded"
    }}>{`Base/ Rounded`}</h2>
    <br />
    <Playground __position={6} __code={'<div className=\"tw-flex tw-gap-3 tw-items-end\">\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn\">\n      <span className=\"tw-i-close\"></span>\n    </button>\n    <p className=\"tw-body3\">base</p>\n  </div>\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn tw-btn-primary tw-btn-rounded\">\n      <span className=\"tw-i-settings\"></span>\n    </button>\n    <p className=\"tw-body3\">rounded</p>\n  </div>\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn tw-btn-secondary tw-btn-rounded\">\n      clique aqui\n    </button>\n    <p className=\"tw-body3\">rounded</p>\n  </div>\n  <div className=\"tw-flex tw-flex-col tw-items-center\">\n    <button className=\"tw-btn tw-btn-clean tw-btn-rounded\">\n      <span className=\"tw-i-close\"></span>\n    </button>\n    <p className=\"tw-body3\">clean</p>\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div className="tw-flex tw-gap-3 tw-items-end">
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn">
        <span className="tw-i-close"></span>
      </button>
      <p className="tw-body3">base</p>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn tw-btn-primary tw-btn-rounded">
        <span className="tw-i-settings"></span>
      </button>
      <p className="tw-body3">rounded</p>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn tw-btn-secondary tw-btn-rounded">clique aqui</button>
      <p className="tw-body3">rounded</p>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center">
      <button className="tw-btn tw-btn-clean tw-btn-rounded">
        <span className="tw-i-close"></span>
      </button>
      <p className="tw-body3">clean</p>
    </div>
  </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      